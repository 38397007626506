
// .page-operator {
//     @media (min-width: 992px) {
//         // .login .body .side-section .side-section-body .side-section-body-container .form-control {
//         //     border-radius: 0 !important;
//         // }
    
//         .input-animation-slide-right {
//             background-color: #ccc;
//         }
    
//         .login.unauthorised {
//             .site-logo {
//                 max-width: 33rem;
//                 height: auto;
//             }
//         }
//     }

//     .btn-success {
//         background: $primary-background-color !important;
//         border: 1px solid $primary-background-color !important;
//         &:hover {
//             background: #194059 !important;
//             border: 1px solid #194059 !important;
//         }
//     }

//     .badge, 
//     .panel-secondary .badge, 
//     .panel-info .badge {
//         background-color: $primary-background-color !important;
//         color: #fff !important;
//     }

//     .tooltip-inner {
//         background-color: $primary-background-color;
//     }
    
//     .tooltip.left .tooltip-arrow {
//         border-left-color: $primary-background-color;
//     }

//     // header {
//     //     background-color: $primary-background-color !important;

//     //     .user-details .username {
//     //         background-color: #fff;
        
//     //         i {
//     //             background-color: #353534;
//     //         }
//     //     }
//     // }
// }
